import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { GoogleMap, Marker, useJsApiLoader,InfoWindow } from '@react-google-maps/api';
import { useAttendnaceStore } from '../store/store';
import { getGeoFence } from '../action/action';
import moment from 'moment';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const containerStyle = {
  width: '100%',
  height: '400px',
};

const GeoFence = ({ modalVisible, closeModal,uiSelectId, locationCoordinates=[],dataValue,dateMonthYear }) => {
  const { selectedUser,mapCenter, setMapCenter } = useAttendnaceStore(state => state);
  const {width}=useWindowDimensions();
  const [locations,setLocations]=useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [centerValue, setCenterValue] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: 'AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ&language=en',
    googleMapsApiKey: 'AIzaSyDqHPPWhcZ1ZvfSTK7X97it2wmjIrmzg3k',
  });

  // useEffect(()=>{
  //   if(modalVisible){
  //   // if(locationCoordinates.length){
  //     getGeoFence({userId:selectedUser?.userId, year:moment().format("YYYY"), month:moment().format("M")},
  //     (res)=>{setLocations(res)},
  //     )
  //   // }else{
  //   //     setLocations([...locationCoordinates])
  //   // }
  //  }
  // },[modalVisible])
  useEffect(() => {
    if (modalVisible) {
      getGeoFence(
        {
          userId: uiSelectId ? uiSelectId : selectedUser?.userId,
          year:dateMonthYear === undefined ? moment().format("YYYY"): dateMonthYear?.split(",")[1],
          month:dateMonthYear === undefined ? moment().format("MM"): dateMonthYear?.split(",")[0],
          date: dataValue?.date,
        },
        (res) => {
          setLocations(res);
          if (res.length > 0) {
            let value = null;
              for (let i = 0; i < res.length; i++) {
                const element = res[i];
                if (element.latitude !== "0.00000000" && element.longitude !== "0.00000000") {
                  value = element;
                  setCenterValue(element)
                  break; 
                }
              }
              console.log("ssssValue",value)
          } 
        }
      );
    }
  }, [modalVisible,dataValue]);

  useEffect(()=>{
    if (centerValue && !isNaN(centerValue.latitude) && !isNaN(centerValue.longitude)) {
      if (centerValue.latitude !== "0.00000000" && centerValue.longitude !== "0.00000000") {
          setMapCenter({ lat: parseFloat(centerValue.latitude), lng: parseFloat(centerValue.longitude) });
        }
      }
  },[centerValue])

  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded && mapRef.current) { 
      mapRef.current.panTo(mapCenter);
    }
  }, [isLoaded, mapCenter,centerValue]);

  return (
    <Modal
      title="Geo Fence"
      open={modalVisible}
      onCancel={closeModal}
      footer={null}
      width={800} centered
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={10}
          onLoad={map => (mapRef.current = map)}
        >
                    {locations.map((marker, index) => {
                      const latitude = parseFloat(marker.latitude);
                      const longitude = parseFloat(marker.longitude);
                    
                      if (!isNaN(latitude) && !isNaN(longitude)) {
                        return (
                          <Marker
                          key={index}
                          position={{ lat: latitude, lng: longitude }}
                          onClick={() => {
                                setSelectedMarker(marker);
                              }}    
                          title={marker.date} 
                          label={marker.date}
                          />
                        //      {/* <InfoWindow>
                        //       <div>
                        //         <p>{marker.date}</p>
                        //       </div>
                        //     </InfoWindow>  */}
                        //   </Marker
                        );
                      } else {
                        console.log(`Invalid coordinates for marker at index ${index}:`, marker);
                        return null; 
                      }
                    })}
                     
        </GoogleMap>
      ) : (
        <div>Loading Google Maps...</div>
      )}
    </Modal>
  );
};

export default GeoFence;
