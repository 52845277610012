import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import credentials from "../../../credentials2";
import credentials2 from "../../../credentials2";
import { async } from "q";
import CryptoJS from 'crypto-js';

const newsLetter = require("../../../assets/newsletter.svg").default;
const appraisal = require("../../../assets/appraisal.svg").default;
const calendar = require("../../../assets/calendar.svg").default;
const ceo = require("../../../assets/ceo.svg").default;
const documents = require("../../../assets/documents.svg").default;
const gmail = require("../../../assets/gmail.svg").default;
const healthcare = require("../../../assets/healthcare.svg").default;
const loudspeaker = require("../../../assets/loudspeaker.svg").default;
const meeting = require("../../../assets/meeting.svg").default;
const presentation = require("../../../assets/presentation.svg").default;
const projectmanagement = require("../../../assets/projectmanagement.svg").default;
const salary = require("../../../assets/salary.svg").default;
const report = require("../../../assets/report.svg").default;
const iBelong = require("../../../assets/ibelong_logo.svg").default
const associateForm = require("../../../assets/associateFormImage.svg").default
const controlPanel = require("../../../assets/controlpanelImage.svg").default
const feedbackManagement = require("../../../assets/feedbackManagementImage.svg").default
const feedIcon = require("../../../assets/feedIcon.svg").default
const lidarIcon = require("../../../assets/lidar.svg").default
const hiringIcon = require("../../../assets/hiringIcon.svg").default

let tt = localStorage.getItem('user')
let loginToken = localStorage.getItem('loginToken')

let hash
if (tt) {
    let user = await decryptData(tt, SECRET_KEY);
    hash = await user?.resourceUser?.passwordToken + "_" + user?.resourceUser?.uid;
}


export const appraisalRoutePath = async () => {

    let appData = localStorage.getItem("AppraisalUserData")

    if (appData) {
        let appUser = await decryptData(appData, SECRET_KEY)

        let appDecryptData = await JSON.parse(appUser)

        if (appDecryptData) {
            if (appDecryptData?.role?.includes("Admin")) {
                return '/appraisal/admin/users';
            }
            else if (appDecryptData?.role?.includes("Manager")) {

                return '/appraisal/manager/';
            }
            else if (appDecryptData?.role?.includes("Associate")) {

                return '/appraisal/associate'
            }
            else {

                return '/homepage'
            }
        }
    }

}

export const hiringRoutePath = async () => {
    let tt = localStorage.getItem("user");
    let token = localStorage.getItem('token')
    let loginToken = localStorage.getItem('loginToken')
    let hash;
    if (tt) {
        let user = await decryptData(tt, SECRET_KEY);
        let accessToken = await decryptData(token, SECRET_KEY);
        let userLoginToken = await decryptData(loginToken, SECRET_KEY);
        console.log("userLoginTokenuserLoginToken", userLoginToken)

        //   return `${credentials?.iBelongUrl}?hash=${hash}`;
        // return 'https://newpmt.brigosha.com/hiring-user'
        return `https://hiringhackathon.brigosha.com/AccessLoginLoading/:token=${userLoginToken}`
    }
};

export const ibelongRoutePath = async () => {
    /*  let tt = localStorage.getItem("user");
     let hash;
     if (tt) {
       let user = await decryptData(tt, SECRET_KEY);
       hash = (await user?.resourceUser?.passwordToken) + "_" + user?.resourceUser?.uid;
       return `${credentials?.iBelongUrl}?hash=${hash}`;
       
     } */
    let tt = localStorage.getItem("user");
    let token = localStorage.getItem('token')
    let loginToken = localStorage.getItem('loginToken')
    let hash;
    if (tt) {
        let user = await decryptData(tt, SECRET_KEY);
        let accessToken = await decryptData(token, SECRET_KEY);
        let userLoginToken = await decryptData(loginToken, SECRET_KEY);
        console.log("userLoginTokenuserLoginToken", userLoginToken)

        //   return `${credentials?.iBelongUrl}?hash=${hash}`;
        // return 'https://newpmt.brigosha.com/hiring-user'
        return `${credentials?.iBelongUrl}?hash=${userLoginToken}`;
    }
};
export const controlPanelPath = async () => {
    let tt = localStorage.getItem("user");
    let hash;
    if (tt) {
        let user = await decryptData(tt, SECRET_KEY);
        hash = (await user?.resourceUser?.passwordToken) + "_" + user?.resourceUser?.uid;
        return `${credentials?.controlPanelUrl}?hash=${hash}`;
    }
};
export const ceoFeedbackRoutePath = async () => {
    let tt = localStorage.getItem("user");
    let token;
    let uid;
    if (tt) {
        let user = await decryptData(tt, SECRET_KEY);
        token = (await user?.resourceUser?.passwordToken);
        uid = (await user?.resourceUser?.uid);
        return `${credentials?.ceoFeedbackUrl}/auth?token=${token}&uid=${uid}`;
    }
};
// const appraisalLink=await appraisalRoutePath();
// console.log('kkkkkkkkkkkkkkkkkkkkkk data link',appraisalLink)

var year = new Date().getFullYear().toString();
var nextyear = (new Date().getFullYear()+1).toString();

export const data = [
    {
        'key': 1,
        "img": newsLetter,
        "title": <div style={{ fontSize: "14px" }}>News Letter</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Read the latest news</div>,
        "link": "/newsletter"

    },
    {
        'key': 2,
        "img": ceo,
        "title": <div style={{ fontSize: "14px" }}>CEO’S Desk</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>A word with the CEO</div>,
        'link': "/ceoDesk/dashboard",
    },
    {
        'key': 3,
        "img": calendar,
        "title": <div style={{ fontSize: "14px" }}>Attendance</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Make your daily presence</div>,
        "link": "/attendance"
    },
    {
        'key': 4,
        "img": loudspeaker,
        "title": <div style={{ fontSize: "14px" }}>Announcement</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Exciting announcement</div>,
        'link': '/announcement',
    },
    {
        'key': 5,
        "img": salary,
        "title": <div style={{ fontSize: "14px" }}>Pay Square</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Access pay square instantly</div>,
        "link": 'https://mypayroll.paysquare.com/'
    },
    {
        'key': 6,
        "img": gmail,
        "title": <div style={{ fontSize: "14px" }}>Mail</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Your brigosha mail one click away</div>,
        "link": 'https://mail.zoho.in/zm/#mail/folder/inbox/'
    },
    {
        'key': 7,
        "img": projectmanagement,
        "title": <div style={{ fontSize: "14px" }}>Resource Management</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Optimize Resources for Goal Achievement</div>,
        "link": "/rmanagement/dashboard"
    },
    {
        'key': 8,
        "img": presentation,
        "title": <div style={{ fontSize: "14px" }}>Project Management</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Enhancing Efficiency and Productivity</div>,
        "link": "/project/dashboard"
    },
    {
        'key': 9,
        "img": healthcare,
        "title": <div style={{ fontSize: "14px" }}>Health Insurance Card {year} - {nextyear}</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Your Safety: Our Top Priority</div>,
        "link": "/health_insurance",
    },
    {
        'key': 10,
        "img": report,
        "title": "Training Documents",
        "subtitle": <div style={{ fontSize: "11px" }}>Becoming brigosha-ready in no time</div>,
        "link": "/documents"
    },
    {
        'key': 11,
        "img": documents,
        "title": "Essential Documents",
        "subtitle": <div style={{ fontSize: "11px" }}>Getting acquainted with brigosha essentials</div>,
        "link": "/documents"
    },
    {
        'key': 12,
        "img": meeting,
        "title": <div style={{ fontSize: "14px" }}>Attendance Portal</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Your Gateway to Attendance Management</div>,
        "link": "/attendancePortal/authentication"
    },
    {
        'key': 13,
        "img": iBelong,
        "title": <div style={{ fontSize: "14px" }}>iBelong</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Associate Engagement at its Best</div>,
        "link": `ibelong`,
    },
    {
        'key': 14,
        "img": appraisal,
        "title": <div style={{ fontSize: "14px" }}>Appraisal</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Associates: Appraise Your Potential</div>,
        "link": 'appraisal',
    },
    {
        'key': 15,
        "img": feedbackManagement,
        "title": <div style={{ fontSize: "14px" }}>Feedback Management</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Creating and Nurturing Feedback Forms</div>,
        "link": "/feedbackmanagement",
    },

    {
        'key': 17,
        "img": controlPanel,
        "title": <div style={{ fontSize: "16px" }}>Control Panel</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Associate blog management</div>,
        "link": `controlPanel`,
    },

    {
        'key': 18,
        "img": hiringIcon,
        "title": <div style={{ fontSize: "16px" }}>Hiring Management</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Applicant hiring oversight</div>,
        "link": `hiring-management`,
    },
    {
        'key': 19,
        "img": feedIcon,
        "title": <div style={{ fontSize: "16px" }}>Feed</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Discover, engage, and stay connected.</div>,
        "link": `/homePage/Feed`,
    },
    {
        'key': 20,
        "img": lidarIcon,
        "title": <div style={{ fontSize: "16px" }}>Lidar Community</div>,
        "subtitle": <div style={{ fontSize: "11px" }}>Shape the future of Lidar technology with us.</div>,
        "link": `lidar`,
    },

  
]

